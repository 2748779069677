
.my-container{ 
 background-color: #000;
 color: white;
position: fixed;
height: auto;
max-width: -webkit-fill-available;
font-size: 14px;
z-index: 99;
}







.banner {
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
}
.banner img{
  height: 500px;
  width: 100%;
  opacity: 98;
  
}
/* .banner img{
  height: "500px";
  width: "100%";
  opacity: 98;
  clip-path: inset(10% 10% 10% 10% round 20%, 20%);
} */

.banner-register .responsive{
  position: absolute;
  text-align: center;
  /* color: white; */
  width: 100%;
  height: auto;

}
.banner-register {
  position: relative;
  text-align: center;
  /* color: white; */
  
  width: 100%;
  height: auto;
}
.banner-register h1{
  position: absolute;
  text-align: center;
  /* display: grid;
  justify-content: center;
 justify-self: center; */
  
  bottom: 35%;
  left: 25%;
  color: white;
}
.banner-register p{
  position: absolute;
  bottom: 25%;
  left: 25%;
  color: white;
}
.banner h1{
  position: absolute;
  text-align: center;
  /* display: grid;
  justify-content: center;
 justify-self: center; */
  
  bottom: 35%;
  left: 25%;
  color: white;
}
.banner p{
  position: absolute;
  bottom: 25%;
  left: 25%;
  color: white;
}
.banner button{
  position: absolute;
  bottom: 21%;
  height: 40px;
  width: 150px;
  left: 45%;
  color: white;
 background-color: rgb(29,153,251);
 border-color: rgb(29,153,251);
}
.my-cont{
  padding-top: 40px;
}
.my-cont h3{
  color: rgb(29,153,251);
  position: relative;
  left: 50px;
}

.my-cont p{
  padding-top: 18px;
 padding-left: 50px;
 
}
.my-cont img{
  height: 350px;
}

.my-bar-cont{
  max-width: -webkit-fill-available;
  background-color: rgb(29,153,251);
  height: auto;
  padding-left: 150px;
}
.teachers h2{
color: rgb(29,153,251);

display: flex;

justify-content: center;
}
.teachers h6{
  display: flex;

  justify-content: center;
}







.container-fluid img{
height: 150px;
width: 150px;
}
.container-fluid p{
  font-size: smaller;
  }
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
footer {
  background-color: #000;
  clip-path: polygon(0% 60px, 50% 0%, 100% 60px, 100% 100%, 0% 100%);
  width: 100%;
  position: relative;
  z-index: 9;
  top:0px;
  bottom: 0px;
  justify-content: left;
}
footer h6{
 right: 3rem;
  color: #eee;
  font-size: medium;
}
footer h5{
  right: 3rem;
   color: #eee;
  
 }

footer p{
  color: white;
  font-size: smaller;
}
.myfooter-ul li{
  list-style-type: none;
  color: #eee;
  padding-right: 5px;
  
  justify-content: space-around;
}

.my-footer img{
  height: 100px;
  width: 150px;
  padding-top: 30px;
 position: relative;
 left: 40%;
}
.my-footer-row{
padding: 40px;
}
.teacherul li{
  padding: "10px";
  list-style-type: none;
}
/* .banner-register h1{
  position: absolute;
  text-align: center;
  bottom: 16rem;
  left: 15%;
  color: white;
}
.banner-register p{
  position: absolute;
  
  bottom: 14rem;
  left: 15%;
  color: white;
  
  } */
  
  .container-homebox .first-pic img{
    height: 450px;
    width: 590px;
    left: 100px;
    overflow: hidden;
  }
  .container-homebox .second-pic img{
    height: 225px;
    width: 250px;
    overflow: hidden;
    position: relative;
    left: 23rem;
  }
  .container-homebox .third-pic {
    background-color: lightgreen;
    height: 225px;
    width: 250px;
    position: relative;
    left: 25rem;
    overflow: hidden;
  }
  .container-homebox .third-pic p{
    position: relative;
    left: 20px;
     top: 55px;
    font-size: small;
  }
  .container-homebox .third-pic h5{
  position: relative;
   left: 20px;
   top: 50px;
  }
  .container-homebox .third-pic button{
   position: relative;
  left: 1rem;
  background-color:lightgreen;
  height: 25px;
  font-size: smaller;
  top: 50px;
  }
  .container-homebox .fourth-pic {
    background-color: yellow;
    height: 225px;
    position: relative;
    top: 225px;
    right: 7%;
    width: 250px;
    overflow: hidden;
  } 
  .container-homebox .fourth-pic p{
    position: relative;
    left: 20px;
   top: 35px;
    font-size: small;
  }
  .container-homebox .fourth-pic p1{
    position: relative;
    left: 20px;
    top: 20px;
    font-size: smaller;
  }
  .container-homebox .fourth-pic h5{
  position: relative;
    left: 20px;
    top: 30px
  }
  .container-homebox .fourth-pic button{
   position: relative;
   top: 30px;
  left: 1rem;
  background-color:yellow;
  height: 25px;
  font-size: smaller;
  }
  .container-homebox .fifth-pic img{
    height: 225px;
    width: 250px;
    overflow: hidden;
    position: relative;
    top: 225px;
    right: 7rem;
  }
  .container-homebox .sixth-pic {
    background-color: red;
    height: 250px;
    width: 295px;
    overflow: hidden;
    position: relative;
    left: 21vh;
  }
  .container-homebox .sixth-pic p{
    position: relative;
   left: 20px;
    top: 65px;
    font-size: small;
  }
  .container-homebox .sixth-pic p1{
    left: 20px;
   top: 50px;
 position: relative;
    font-size: smaller;
  }
  .container-homebox .sixth-pic h5{
  position: relative;
  top: 60px;
    left: 20px !important;
  }
  .container-homebox .sixth-pic button{
   position: relative;
   top: 90px;
  right: 8rem;
  background-color:red;
  height: 25px;
  font-size: smaller;
  }
  .container-homebox .seventh-pic img{
    height: 250px;
    width: 295px;
    overflow: hidden;
    position: relative;
    left: 109px;
  }
  .container-homebox .eight-pic {
    background-color: grey;
    height: 250px;
    width: 494px;
    overflow: hidden;
    position: relative;
    left: 84px;
  }
  .container-homebox .eight-pic p{
    position: relative;
   left: 20px !important;
    top: 55px !important;
    font-size: small;
    color: white;
  }
  .container-homebox .eight-pic p1{
    position: relative;
    left: 20px !important;
 top: 35px !important;
    color: white;
    font-size: smaller;
  }
  .container-homebox .eight-pic h5{
    color: white;
    position: relative;
    top: 45px;
    left: 20px !important;
  }
  .container-homebox .eight-pic button{
   position: relative;
  left: 18px;
  top: 50px;
  background-color:grey;
  height: 25px;
  font-size: smaller;
  color: white;
  border-color: #eee;
  }
  .container-homebox .ninth-pic img{
    height: 250px;
    width: 295px;
    overflow: hidden;
    position: relative;
    left: 109px;
  }
  .container-homebox .tenth-pic {
    background-color: skyblue;
    height: 250px;
    width: 295px;
    overflow: hidden;
    position: relative;
    left: 84px;
  }
  .container-homebox .tenth-pic p{
    position: relative;
   left: 20px !important;
   top: 35px !important;
    font-size: small;
  }
  .container-homebox .tenth-pic p1{
    left: 20px;
    position: relative; 
    top: 20px;
    font-size: smaller;
  }
  .container-homebox .tenth-pic h5{
  top: 25px !important;
  position: relative;
    left: 20px;
  }
  .container-homebox .tenth-pic button{
   position: relative;
  left: 25px;
  top: 20px;
  background-color:skyblue;
  height: 25px;
  font-size: smaller;
  }
  .container-homebox .eleventh-pic img{
    height: 250px;
    width: 494px;
    overflow: hidden;
    position: relative;
    left: 72px;
  }
  .sign-in{
    padding-top: 0;
  
  }
  .sign-in img{
    height: 400px;
    width: 450px;
    opacity: 22;
  }
  .sign-in h4{
    position: relative;
    bottom: 14rem;
   left: 3%;
   color: #fff;
  }
  .sign-in p{
    position: relative;
    bottom: 14rem; 
    left: 3%;
    color: white;    
    }

    
   
  
    
   
   
   
    .page-2{
      height: 270px;
    }
    .page-2 .custom-select{
      height: 40px;
      width: 320px;
      top: 10px;
      position: relative;
      opacity: 0.4;
     }
     .page-2 .page-2-certificate{
      height: 40px;
      width: 320px;
      top: 20px;
     position: relative;
     opacity: 0.4;
     }
     .page-2 .page-2-button{
      height: 40px;
      width: 120px;
      background-color:rgb(29,153,251);
      border-color: rgb(29,153,251);
      color: white;
      position: relative;
      top: 30px;
      /* left: 49vh; */
    }
   
   
   .Header {
    position: fixed;
    max-height: 90px;
    display: grid;
    grid-template-areas: "logo nav";
    width: 100%;
    z-index: 99;
    top: 45px;
    height: 5rem;
    text-align: right;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .Logo {
    grid-area: logo;
    padding-left: 30px;
    height: 70px;   
  }
  
  .Nav {
    display: grid;
    
    grid-area: nav;
    grid-template-columns: repeat(6, auto);
    align-items: center;
    justify-items: center;
  }
  .Nav a {
    color: black;
    font-size: 13px;
    font-weight: 400;
    transition: 0.5s;
    text-decoration: none;
  }
  .Nav a:hover {
    transform: scale(1.1);
  }
  .Nav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: 70ms;
  }
  
  .Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
  }
  
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
  }
  .Burger:active {
    transform: scale(1.2);
  }
  
  @media (max-width: 770px) {
    .Header {
      top: 160px;
      grid-template-areas: "logo burger" "nav nav";
    }
    .Nav {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 20px;
  
      padding: 30px 0 30px;
      background: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .Burger {
      display: inline;
    }
  }
  
  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }
  



  div.gallery {
    border: 1px solid #ccc;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery img {
    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
  }
  
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  .registerforms .buttontrue{
background-color: lightgreen;
border-color: lightgreen;
  }
  .registerforms .buttonfalse{
    background-color: white;
    border-color: lightgreen;
      }
.coding-page-cont .jsbtnrow .jsbuttonclicked{
  height: 50px;
  width: 300px;
  background-color: rgb(29,153,251);
  border: none;
}
.coding-page-cont .jsbtnrow .notclicked{
  height: 50px;
  width: 300px;
  background-color: lightgrey;
  border: none;
}
.science-fair .science-row .clicked{
  height: 50px;
  width: 300px;
  width: 100%;
  background-color: rgb(29,153,251);
  border: none;
}
.science-fair .science-row .notclicked{
  height: 50px;
  width: 300px;
  background-color: lightgrey;
  border: none;
}
.school-cont .cont-col .clicked{
  height: 50px;
  width: 100%;
  background-color: rgb(29,153,251);
  border: none;

 
  
}
.school-cont .cont-col .notclickedyet{
  height: 50px;
  width: 300px;
  background-color: lightgrey;
  border: none;
}
.error {
  color:#db2269;
  font-size: 0.625em;
  display: relative;
}
.timecont  .card1color {
  
  width: 35vh;
  min-height: 42vh;
  background-color: white;
  border: 1px solid grey;
  opacity: 0.9;
}

.timecont  .card2color {
  
  width: 35vh;
  min-height: 42vh;
  background-color: rgb(115, 189, 40);
  border: none;
}
.subcribecont .subscribed {
background-color: green;
border: none;
}
.subcribecont .nonsubscribed {
  background-color: grey;
  border: none;
}
.subcribecont .subscribed1 {
  background-color: red;
  border: none;
  }
  .subcribecont .nonsubscribed2 {
    background-color: grey;
    border: none;
  }